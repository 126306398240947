html {
  touch-action: none;
  min-height: -webkit-fill-available;
  min-width: 320px;
}

html, body, #root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.5);
  border-radius: 8px;
  cursor: pointer;
}

::-webkit-scrollbar-corner {
  background: #0A2333;
}
